import abbreviate from "number-abbreviate";
import { formatIndianNumber } from "./formatIndianNumber";

export const formatNumber = (num, currency = "") => {
  const numberValue = Number(num);
  if (currency.toUpperCase() === "INR") {
    return formatIndianNumber(numberValue);
  }
  return abbreviate(numberValue, 2);
};
