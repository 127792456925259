import React from "react";
import {Link as RouterLink} from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";
import DashboardCard from "./DashboardCard";
import {ButtonNav} from "../Buttons/Buttons";
import clsx from "clsx";
import {Grid, Stack} from "@mui/material";
import {formatNumber} from "../../Util/formatNumber";
import Format from "../../Util/format";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  cardValue: {
    fontWeight: 700,
    fontSize: 48,
    marginTop: theme.spacing(2),
  },
  cardTitle: {
    fontWeight: 700,
    fontSize: 20,
    marginTop: theme.spacing(1),
  },
  label: {
    fontSize: 16,
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 400,
  },
  linearProgress: {
    height: 8,
    borderRadius: 4,
    marginTop: theme.spacing(1),

    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#F6F9FD",
    },

    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "#FFE034",
      borderRadius: 4,
    },
  },
  wrapStages: {
    marginTop: 2,
    paddingBottom: 2.5,
  },
  stage: {
    marginTop: 3.25,
  },
  quotes: {
    marginTop: "auto",
    paddingBottom: 2.5,
  },
  quoteListItem: {
    borderColor: "#EEEEEE",
    borderStyle: "solid",
    paddingY: 1.5,
  },
  quoteListItemBorderTop: {
    borderTopWidth: 1,
  },
  activitiesWrapLink: {
    marginLeft: 0.5,
    display: "flex",
  },
  activitiesBtn: {
    padiding: 0,
  },
}));

const DashboardOverviewCards = ({
  sumOfPremiums,
  sumOfRejectedPremiums,
  sumOfAcceptedPremiums,
  sumOfMonitoredPremiums,
  businessPartnersCount,
  recentQuotes,
  currency,
  lossRatio,
  sumOfClaims
}) => {
  const classes = useStyles();

  return (
      <Grid container className={classes.root} spacing={2}>

        {/* Sum of premiums card */}
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard>
            <Typography className={classes.cardTitle}>Sum of
              premiums</Typography>
            <Typography className={classes.cardValue}>
              {(currency || "USD").toUpperCase()} {formatNumber(sumOfPremiums,
                currency)}{" "}
              <Typography component="span" className={classes.label}>
                Total
              </Typography>
            </Typography>
            <Box className={classes.wrapStages}>
              <Box className={classes.stage}>
                <Grid container justifyContent="space-between"
                      alignItems="center">
                  <Grid item>
                    <Typography className={classes.label}>Sum of rejected
                      premiums</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.label}>{formatNumber(
                        sumOfRejectedPremiums)}</Typography>
                  </Grid>
                </Grid>
                <LinearProgress
                    className={classes.linearProgress}
                    variant="determinate"
                    value={Math.min(
                        (sumOfRejectedPremiums / (sumOfPremiums || 1)) * 100,
                        100)}
                />
              </Box>
              <Box className={classes.stage}>
                <Grid container justifyContent="space-between"
                      alignItems="center">
                  <Grid item>
                    <Typography className={classes.label}>Sum of accepted
                      premiums</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.label}>{formatNumber(
                        sumOfAcceptedPremiums)}</Typography>
                  </Grid>
                </Grid>
                <LinearProgress
                    className={classes.linearProgress}
                    variant="determinate"
                    value={Math.min(
                        (sumOfAcceptedPremiums / (sumOfPremiums || 1)) * 100,
                        100)}
                />
              </Box>
            </Box>
          </DashboardCard>
        </Grid>

        {/* Insured business partners card */}
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard>
            <Typography className={classes.cardTitle}>Insured business
              partners</Typography>
            <Typography className={classes.cardValue}>
              {formatNumber(businessPartnersCount)}{" "}
              <Typography component="span" className={classes.label}>
                Total
              </Typography>
            </Typography>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Grid container justifyContent="space-between"
                      alignItems="center">
                  <Grid item>
                    <Typography className={classes.label}>Loss
                      ratio</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.label}>
                      {lossRatio == null ? "0" : Format.percentageWrapFormat(
                          lossRatio)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between"
                      alignItems="center">
                  <Grid item>
                    <Typography className={classes.label}>Sum of
                      claims</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.label}>
                      {sumOfClaims?.amount != null ? formatNumber(
                          sumOfClaims.amount, sumOfClaims.currency) : "---"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between"
                      alignItems="center">
                  <Grid item>
                    <Typography className={classes.label}>Sum of
                      monitored premiums</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.label}>
                      {formatNumber(
                          sumOfMonitoredPremiums)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DashboardCard>
        </Grid>

        {/* Recent quotes card, only if recentQuotes exist */}
        {recentQuotes?.length > 0 && (
            <Grid item xs={12} sm={6} md={4}>
              <DashboardCard>
                <Typography className={classes.cardTitle}>Recent
                  quotes</Typography>
                <Stack className={classes.quotes}>
                  {recentQuotes.map((item, idx) => (
                      <Stack
                          key={item.id}
                          justifyContent="space-between"
                          alignItems="center"
                          className={clsx(
                              classes.quoteListItem,
                              idx !== 0 && classes.quoteListItemBorderTop
                          )}
                          direction="row"
                          spacing={1}
                      >
                        <Typography className={classes.label} noWrap>
                          {item.name}
                        </Typography>
                        <Stack container alignItems="center" direction="row">
                          <Typography className={classes.label}>see</Typography>
                          <Box className={classes.activitiesWrapLink}>
                            <ButtonNav
                                to={`/quotations/quotation/${item.id}?backTo=/dashboard`}
                                component={RouterLink}
                                className={classes.activitiesBtn}
                            >
                              <ArrowRightAltIcon className={classes.arrowIcon}/>
                            </ButtonNav>
                          </Box>
                        </Stack>
                      </Stack>
                  ))}
                </Stack>
              </DashboardCard>
            </Grid>
        )}
      </Grid>
  );
};

export default DashboardOverviewCards;
