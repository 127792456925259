import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from 'dayjs';
import Led from '../Led/Led';
import formatMissingValue from '../../Util/formatMissingValue';
import plural from '../../Util/pluralize';
import { ButtonNav } from '../Buttons/Buttons';
import QuoteNewCustomizationStack from '../Quotes/QuoteNew/QuoteNewCustomization/QuoteNewCustomizationStack';

const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.stripe,
    textTransform: 'uppercase',
    height: 48,
    '& th': {
      fontSize: 12,
      fontWeight: 600,
      opacity: 0.54
    }
  },
  tableRow: {
    height: 57,
    '& > *': {
      borderBottom: '1px solid #EFEFEF',
      fontSize: 16,
    }
  },
  tableCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  tableLabel: {
    fontSize: 18,
    fontWeight: 700,
  }
}));

function Row(props) {
  const {
    row,
    program,
    onNameChange,
    onSumInsuredIndividualChange,
    onPremiumInsuredNumberChange,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const defaultLabel = row.dataSet + ' Coverage For ' + row.monitoringTarget?.name;
  const maxRateValue = parseInt((row.trigger + (100 - (row.minPayout?.amount ?? 0)) / (row.payoutPerUnit?.amount ?? 1)) * 100) / 100;

  return (
    <React.Fragment>
      <TableRow className={classes.tableRow}>
        <TableCell>
          <Stack direction="row" alignItems="center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              sx={{ml: -1}}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            <Box position="relative" sx={{minWidth: 11, height: 11, mr: 1}}>
              <Led status={row.uiState} />
            </Box>
            <Box className={classes.tableCell} sx={{left: '64px !important'}}>{row.label ?? defaultLabel}</Box>
          </Stack>
        </TableCell>
        <TableCell><Box className={classes.tableCell}>{formatMissingValue(row?.monitoringTarget?.name)}</Box></TableCell>
        <TableCell><Box className={classes.tableCell}>{formatMissingValue(dayjs(row.start).format(dayjs(row.start).isSame(dayjs(row.end), 'year') ? 'DD-MMM' : "DD-MMM'YY") + ' to ' + dayjs(row.end).format("DD-MMM'YY"))}</Box></TableCell>
        <TableCell><Box className={classes.tableCell}>{formatMissingValue(parseInt(row.trigger * 100) / 100 + " " + plural(row.triggerUnit, row.trigger))}</Box></TableCell>
        <TableCell><Box className={classes.tableCell}>{formatMissingValue(maxRateValue) + " " + (row.triggerUnit ?? '')}</Box></TableCell>
        <TableCell><Box className={classes.tableCell}>{formatMissingValue(program?.Currency + " " + (row.quantity * row.sumInsuredIndividual * row?.payoutPerUnitPct).toFixed(2) + " per " + plural(row.triggerUnit, row.trigger))}</Box></TableCell>
        <TableCell><Box className={classes.tableCell}>{formatMissingValue(program?.Currency + " " + (row.quantity * row.sumInsuredIndividual).toFixed(2))}</Box></TableCell>
        <TableCell sx={{pl: 0}}>
          <ButtonNav to={`/coverages/coverage/${row.id}`} component={RouterLink} size="small">
            <ArrowForwardIcon className={classes.arrowIcon}/>
          </ButtonNav>
        </TableCell>
      </TableRow>
      <TableRow sx={{ background: '#F9F9F9' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, maxWidth: 800 }}>              
              <QuoteNewCustomizationStack
                id={row.id}
                hideBorderBottom={true}
                payoutValue={row.minPayout.amount}
                onPremiumInsuredNumberChange={onPremiumInsuredNumberChange}
                onNameChange={onNameChange}
                onSumInsuredIndividualChange={onSumInsuredIndividualChange}
                numberOfInsured={row.quantity}
                sumInsuredIndividual={row.sumInsuredIndividual}
                sumInsured={row.quantity * row.sumInsuredIndividual}
                coverage={row}
                program={program.InsuredValue}
                country={program.Country}
                insuredUnit={program.InsuredUnit}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CoverageTable(props) {
  const { data = [], program } = props;

  const classes = useStyles();
  const theme = useTheme();
  const formatCurrency = (currency, amount) => `${currency} ${Number(amount?.toFixed(2)).toLocaleString()}`;

  const [coverages, setCoverages] = useState([]);

  const totalSumInsured = useMemo(() => {
    let sum = 0;
    coverages.forEach((coverage) => {
      sum += coverage.quantity * coverage.sumInsuredIndividual;
    })

    return sum;
  }, [coverages])

  const estimatedPremiumValue = useMemo(() => {
    let sum = 0;
    coverages.forEach((coverage) => {
      sum += (coverage.grossPremiumIndicationPct ?? 0) * coverage.sumInsuredIndividual * coverage.quantity;
    })

    return sum;
  }, [coverages])

  const onNameChange = useCallback((event, {id}) => {
    setCoverages(prev => prev.map(coverage => coverage.id === id ? ({
      ...coverage,
      indexDefinition: {
        ...coverage.indexDefinition,
        output: {
          ...coverage.indexDefinition.output,
          name: event.target.value,
        }
      }
    }) : coverage));
  }, [coverages])

  const onSumInsuredIndividualChange = useCallback((event, {id}) => {
    setCoverages(prev => prev.map(coverage => coverage.id === id ? ({
      ...coverage,
      sumInsuredIndividual: event.target.value,
    }) : coverage));
  }, [coverages])

  const onPremiumInsuredNumberChange = useCallback((event, {id}) => {
    setCoverages(prev => prev.map(coverage => coverage.id === id ? ({
      ...coverage,
      quantity: event.target.value,
    }) : coverage));
  }, [coverages])

  useEffect(() => {
    setCoverages(data.map(coverage => ({
      ...coverage,
      sumInsuredIndividual: coverage.sumInsuredIndividual ?? program.DefaultSIperUnit ?? 0,
      quantity: coverage.quantity ?? 1,
    })));
  }, [data, program])

  return (
    <TableContainer>
      <Table size="small">
        <TableHead className={classes.tableHead}>
          <TableRow key="table-head">
            <TableCell sx={{pl: 5, width: '25%'}}>Coverage</TableCell>
            <TableCell sx={{width: '16%'}}>Location</TableCell>
            <TableCell sx={{width: '16%'}}>Risk period</TableCell>
            <TableCell sx={{width: '10%'}}>Trigger</TableCell>
            <TableCell sx={{width: '10%'}}>Exit</TableCell>
            <TableCell sx={{width: '11%'}}>Payout</TableCell>
            <TableCell sx={{width: '10%'}}>Max payout</TableCell>
            <TableCell sx={{width: '2%'}} />
          </TableRow>
        </TableHead>
        <TableBody>
          {coverages.map((row) => (
            <Row
              key={row.name}
              row={row}
              program={program}
              onNameChange={onNameChange}
              onSumInsuredIndividualChange={onSumInsuredIndividualChange}
              onPremiumInsuredNumberChange={onPremiumInsuredNumberChange}
            />
          ))}
          {!!program && (
            <>
              <TableRow className={classes.tableRow} sx={{backgroundColor: theme.palette.stripe}}>
                <TableCell className={classes.tableLabel}>Total Sum Insured</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell className={classes.tableLabel}>{formatCurrency(program?.Currency, totalSumInsured)}</TableCell>
                <TableCell />
              </TableRow>
              <TableRow sx={{height: 18}} />
              <TableRow className={classes.tableRow} sx={{backgroundColor: theme.palette.stripe}}>
                <TableCell className={classes.tableLabel}>Estimated Premium</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell>{(estimatedPremiumValue * 100 / (totalSumInsured ?? 1)).toFixed(1)}%</TableCell>
                <TableCell className={classes.tableLabel}>{formatCurrency(program?.Currency, estimatedPremiumValue)}</TableCell>
                <TableCell />
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
