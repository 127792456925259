import React, { useMemo, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

// Content
import content from "../../Content/content";

// Components
import Aside from "../Aside/Aside";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import QuoteContainer from "../Quote/QuoteContainer";
import CoverageContainer from "../Coverage/CoverageContainer";
import PolicyContainer from "../Policy/PolicyContainer";
import PolicyDraftContainer from "../Policy/PolicyDraftContainer";
import CoveragesNewContainer from "../Coverages/CoveragesNewContainer";
import Dashboard from "../Dashboard/Dashboard";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HeaderContainer from "../Header/HeaderContainer";
import QuotesContainer from "../Quotes/QuotesContainer";
import PoliciesContainer from "../Policies/PoliciesContainer";
import CoveragesContainer from "../Coverages/CoveragesContainer";
import TermSheetContainer from "../TermSheet/TermSheetContainer";
import PartnersContainer from "../Partners/PartnersContainer";
import PartnerContainer from "../Partner/PartnerContainer";
import PartnerNew from "../Partners/PartnerNew";
import QuoteNew from "../Quotes/QuoteNew/QuoteNew";
import Monitoring from "../Monitoring/Monitoring";
import EventDetailsPage from "../EventDetails/EventDetailsPage";
import DataCatalog from "../DataCatalog/DataCatalog";
import Programs from "../Programs/Programs";
import ProgramDetails from "../ProgramDetails/ProgramDetails";
import EventDetailsExternal from "../EventDetailsExternal/EventDetailsExternal";
import EventExternal from "../EventExternal/EventExternal";

// Icons
import { useConfigs } from "../../hooks/useConfigs";
import { CircularProgress } from "@mui/material";
import XCube from "../XCube/XCube";
import { useSidebarContext } from "../../Contexts/SidebarContext";

const getRoutes = (configs = {}) => {
  return [
    {
      path: "/dashboard",
      component: Dashboard,
      hasAccess: configs.dashboard
    },
    {
      path: "/coverages",
      component: CoveragesContainer,
      exact: true,
      hasAccess: configs.coverages,
    },
    {
      path: "/quotations",
      component: QuotesContainer,
      exact: true,
      hasAccess: configs.quotations,
    },
    {
      path: "/clients",
      component: PartnersContainer,
      exact: true,
      hasAccess: configs.clients,
    },
    {
      path: "/clients/new",
      component: PartnerNew,
      exact: true,
      hasAccess: configs.clients,
    },
    {
      path: "/clients/:id",
      component: PartnerContainer,
      exact: true,
      hasAccess: configs.clients,
    },
    {
      path: "/data-catalog",
      component: DataCatalog,
      exact: true,
      hasAccess: configs.dataCatalog,
    },
    {
      path: "/quotations/new",
      component: QuoteNew,
      exact: true,
      hasAccess: configs.quotations,
    },
    {
      path: "/quotations/quotation/:id",
      component: QuoteContainer,
      hasAccess: configs.quotations,
    },
    {
      path: "/policies",
      component: PoliciesContainer,
      exact: true,
      hasAccess: configs.policies,
    },
    {
      path: "/policies/policy/:id",
      component: PolicyContainer,
      hasAccess: configs.policies,
    },
    {
      path: "/policies/policy-draft/:id",
      component: PolicyDraftContainer,
      hasAccess: configs.policies,
    },
    {
      path: "/monitoring",
      component: Monitoring,
      exact: true,
      hasAccess: configs.monitoring,
    },
    {
      path: "/monitoring/event/:id",
      component: EventDetailsPage,
      hasAccess: configs.monitoring,
    },
    {
      path: "/coverages/new",
      component: CoveragesNewContainer,
      hasAccess: configs.coverages,
    },
    {
      path: "/coverages/coverage/:id",
      component: CoverageContainer,
      hasAccess: configs.coverages,
    },
    {
      path: "/data-explorer",
      component: XCube,
      hasAccess: configs.xcube,
    },
    {
      path: "/coverages/term-sheet",
      component: TermSheetContainer,
      hasAccess: true,
    },
    {
      path: "/programs",
      component: Programs,
      exact: true,
      hasAccess: configs.programs,
    },
    {
      path: "/programs/:id",
      component: ProgramDetails,
      hasAccess: configs.programs,
    },
    {
      path: "/m/:id",
      component: EventDetailsExternal,
      hasAccess: true,
    },
    {
      path: "/m",
      component: EventExternal,
      hasAccess: true,
    },
  ];
};

const Portal = ({ theme }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { profile, isLoading } = useConfigs();
  const { isOpen, setIsOpen } = useSidebarContext();

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleDrawerToggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  const mainStyles = useMemo(
    () => ({
      base: {
        transition: `width ${theme.transitions.duration.leavingScreen}ms , margin ${theme.transitions.duration.leavingScreen}ms`,
      },
      active: {
        marginLeft: -theme.drawerWidth,
      },
    }),
    [theme],
  );

  const routes = useMemo(() => getRoutes(profile?.modules), [profile?.modules]);

  const currentPath = window.location.pathname;
  const checkPath = currentPath === '/m' || currentPath.startsWith('/m/');

  if (checkPath) {
    return (
      <BrowserRouter>
        <main className="main" style={mainStyles.base}>
          <Switch>
            {routes
              .filter((route) => route.hasAccess)
              .map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ))}
          </Switch>
        </main>
      </BrowserRouter>
    );
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        sx={{ inset: 0 }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const hasAccessRoute = routes.filter((route) => route.hasAccess);
  const firstRoute = hasAccessRoute[0]?.path;
  const monitoringRoute = hasAccessRoute.find((item) => item.path === "/monitoring");

  return (
    <BrowserRouter>
      <HeaderContainer drawerToggle={handleDrawerToggle} />
      <main
        className="main"
        style={isOpen ? mainStyles.base : { ...mainStyles.base, ...mainStyles.active }}
      >
        <Aside
          roles={profile?.roles ?? []}
          configs={profile?.modules}
          drawerToggle={handleDrawerToggle}
          drawerIsOpen={isOpen}
          onModalOpen={handleModalOpen}
        />
        <Switch>
          {routes
            .filter((route) => route.hasAccess)
            .map((route) => (
              <Route
                key={route.path}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
          {/* Default page to re-direct */}
          <Redirect to={monitoringRoute?.path ?? firstRoute} />
        </Switch>

        {/* Modal */}
        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Box color={theme.palette.error.main}>{content.portal.modal.title}</Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {content.portal.modal.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose} color="primary" autoFocus>
              {content.portal.modal.cta}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Modal ./end */}
      </main>
    </BrowserRouter>
  );
};

export default Portal;
